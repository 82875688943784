import React, { useContext, useEffect, useState } from 'react'
import 'isomorphic-fetch'
import ContentContext from '../Context/ContentContext'
import { useFormContext } from 'react-hook-form'

const generateLabelBtn = ({ labelURL, open }) => {
  const ctx = useContext(ContentContext)
  const { formState } = useFormContext()
  const [creatingLabel, setCreatingLabel] = useState(false)

  useEffect(() => {
    if (labelURL.length > 0 && open) {
      window.open(labelURL, '_blank', 'noopener noreferrer')
    }
  }, [labelURL, open])

  useEffect(() => {
    if (formState.isSubmitting) {
      // When we submit the form, formState.isSubmitting switches back to false before we're done creating a label,
      // so using a state variable as a "creating label" flag
      setCreatingLabel(true)
    }
    const timeOutId = setTimeout(() => (!formState.isValid || !formState.isSubmitted) && setCreatingLabel(false), 300)
    return () => clearTimeout(timeOutId)
  }, [formState.isSubmitting, formState.isSubmitted])

  const generateBtn = () => {
    if (formState.isSubmitted && labelURL.length > 0) {
      return (
        <a href={labelURL} rel='noopener noreferrer' target='_blank' className='hw-button hw-button--primary' data-testid='viewLabelLink'>
          {ctx.t('btnlabels.viewreturnlabel')}
        </a>
      )
    } else {
      return (
        /* The conditional deciding type should be replaced with isValid when we upgrade react-hook-form to > 6. */
        <button
          type={Object.values(formState.errors).length === 0 ? 'submit' : 'button'}
          className='hw-button hw-button--primary'
          name='generateLabelBtn'
          data-testid='generateLabelBtn'
          disabled={formState.isValid && creatingLabel && labelURL?.length === 0}
        >
          {creatingLabel && formState.isValid && labelURL?.length === 0 ? ctx.t('btnlabels.creatinglabel') : ctx.t('btnlabels.generatelabel')}
        </button>
      )
    }
  }

  return (
    <div className='hw-grid__item'>
      {generateBtn()}
    </div>
  )
}

export default generateLabelBtn
