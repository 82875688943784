import 'isomorphic-fetch'

function validatePostalCode (postalCode, countryCode) {
  return callApi(get, `/postalCode/?pnr=${postalCode}&country=${countryCode}`)
}

function generateLabel (body) {
  return callApi(post, '/returnInfo/', body)
}

function get () {
  return {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }
}

function post (body) {
  function payload (body) {
    if (typeof body === 'object') {
      return JSON.stringify(body)
    } else {
      return body
    }
  }

  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: payload(body)
  }
}

function callApi (method, fullUrl, body) {
  function checkStatus (response) {
    if (response.ok || response.status === 422) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error(response.statusText || 'Something went wrong with the api call'))
    }
  }

  function checkContentType (response) {
    const contentType = response.headers.get('content-type')

    if (response.status === 204) {
      // we don't care about content-type for responses with no content
      return response
    } else if (contentType.includes('application/json') || contentType.includes('application/vnd.api+json')) {
      return response
    } else {
      throw new Error('Received non-json response from API')
    }
  }

  return fetch(fullUrl, method(body))
    .then(checkStatus)
    .then(checkContentType)
    .then(response => (response.status === 204 ? {} : response.json()))
}

export default { validatePostalCode, generateLabel }
