
export function getCountryName (countryCode) {
  let countryName = ''
  switch (countryCode) {
    case 'NO':
      countryName = 'countries.norway'
      break
    case 'DK':
      countryName = 'countries.denmark'
      break
    case 'SE':
      countryName = 'countries.sweden'
      break
    case 'FI':
      countryName = 'countries.finland'
      break
  }
  return countryName
}
