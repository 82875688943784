import React, { useState, useContext, useEffect } from 'react'
import ContentContext from '../../Context/ContentContext.js'
import 'url-search-params-polyfill'
import 'isomorphic-fetch'
import * as Sentry from '@sentry/browser'
import './AuthCodeSearchInput.css'

const authCodeSearchInput = ({ setAuthCode, setFetchError, setAuthNotFound, setConfirmation, setReference, setInvalidReturnAgreement }) => {
  const context = useContext(ContentContext)
  const [internalAuthCode, setInternalAuthCode] = useState('')
  const [isLoadingCode, setIsLoadingCode] = useState(false)
  const urlCode = new URLSearchParams(location.search).get('q')
  const referenceParam = new URLSearchParams(location.search).get('ref')

  let loadingTimer = ''
  const searchForAuthCode = (internalAuth) => {
    if (internalAuth.length > 0) {
      fetch('/returnInfo/' + internalAuth, { method: 'GET', credentials: 'include' })
        // Delays the visual loading state, so it dont always flash between states if everything works normally
        .then((response) => { loadingTimer = setTimeout(() => { setIsLoadingCode(true) }, 1000); return response })
        .then((response) => {
          Sentry.addBreadcrumb({
            category: 'fetch',
            message: 'Server responded with status ' + response.statusText + ' when fetching authcode',
            level: Sentry.Severity.Info
          })
          return response
        })
        .then(async response => {
          if (response.ok) return response.json()
          // TODO: make this more stable/better, this is just hacked to to compensate for missing non-JSON error handlingski
          if (response.status === 404) {
            return {errors: true, text: await response.text()}
          }
          throw new Error(response.statusText + '::' + response.body)
        })
        .then(resp => {
          setConfirmation('')
          if (resp.errors) {
            let invalidCode =false;
            if(resp.text) invalidCode = (String(resp.text) === "Invalid return code")
            if(invalidCode){
              setInvalidReturnAgreement(true)
            } else {
              setAuthNotFound(true)
            }
            clearTimeout(loadingTimer)
            setIsLoadingCode(false)
          } else {
            setAuthNotFound(false)
            setAuthCode(internalAuth)
            if (resp.addresses) {
              context.setReceiverAddress(resp.addresses.filter(address => address.returnsEnabled))
            } else {
              context.setReceiverAddress([])
            }
            clearTimeout(loadingTimer)
            setIsLoadingCode(false)
            setFetchError(false)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          setFetchError(true)
          context.setReceiverAddress([])
          clearTimeout(loadingTimer)
          setIsLoadingCode(false)
        })
    }
    if (referenceParam !== null) {
      history.pushState({}, null, '?q=' + internalAuth + '&ref=' + referenceParam)
    } else {
      history.pushState({}, null, '?q=' + internalAuth)
    }
  }

  const searchBtnState = isLoadingCode
    ? <button className='hw-button hw-button--primary hw-trace__button' disabled>{context.t('btnlabels.searching')}</button>
    : (
      <button
        type='submit'
        className='hw-button hw-button--primary hw-trace__button'
        data-testid='returnCodeSearchBtn'
        onClick={() => searchForAuthCode(internalAuthCode)}
      >
        {context.t('btnlabels.search')}
      </button>
    )

  useEffect(() => {
    if (referenceParam !== null) {
      setReference(referenceParam)
    }
    if (urlCode !== null) {
      setInternalAuthCode(urlCode)
      searchForAuthCode(urlCode)
    }
  }, [])

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <label className='hw-color-white hw-h4' htmlFor='returnCodeInput'>{context.t('authCodeSearch.inputAuthCodeLabel')}</label>
        <div className='hw-trace'>
          <div className='hw-trace__inner'>
            <input
              className='hw-trace__input'
              id='returnCodeInput'
              type='text'
              value={internalAuthCode}
              onChange={(event) => setInternalAuthCode(event.target.value.trim())}
              data-testid='returnCodeInput'
              placeholder={context.t('authCodeSearch.inputAuthCodePlaceholder')}
              autoFocus
            />
          </div>
          {searchBtnState}
        </div>
      </form>
    </>
  )
}

export default authCodeSearchInput
