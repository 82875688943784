import React, { useContext } from 'react'
import ReturnForms from '../component/ReturnForms.jsx'
import Confirmation from '../component/Confirmation.jsx'
import NoResultMessage from '../component/NoResultMessage.jsx'
import FetchErrorMessage from '../component/FetchErrorMessage.jsx'
import InvalidReturnAgreementMessage from '../component/InvalidReturnAgreementMessage.jsx'
import ContentContext from '../Context/ContentContext'

const contentContainer = ({ authCode, fetchError, setFetchError, authNotFound, confirmation, setConfirmation, reference, invalidReturnAgreement}) => {
  const context = useContext(ContentContext)
  const returnForm =
    <div className='hw-block hw-block--mt hw-block--mb'>
      <div className='hw-grid'>
        <ReturnForms authCode={authCode} setFetchError={setFetchError} setConfirmation={setConfirmation} reference={reference} />
      </div>
    </div>

  const confirmationPage =
    <div className='hw-block hw-block--mt hw-block--mb'>
      <Confirmation confirmation={confirmation} setConfirmation={setConfirmation} />
    </div>

  const noResultMsg =
    <div className='hw-block hw-block--mt'>
      <NoResultMessage />
    </div>

  const fetchErrorMsg =
    <div className='hw-block hw-block--mt hw-block--mb'>
      <FetchErrorMessage />
    </div>

  const invalidReturnAgreementMsg =
      <div className='hw-block hw-block--mt hw-block--mb'>
        <InvalidReturnAgreementMessage />
      </div>

  const formOrMessagetoShow = () => {
    if (authNotFound) {
      return noResultMsg
    } else if (fetchError) {
      return fetchErrorMsg
    } else if (invalidReturnAgreement) {
      return invalidReturnAgreementMsg
    } else if (confirmation !== '') {
      return confirmationPage
    } else if (authCode !== '' && context.receiverAddresses.length > 0) {
      return returnForm
    } else {
      return null
    }
  }

  return (
    <div className='hw-container'>
      {formOrMessagetoShow()}
    </div>
  )
}

export default contentContainer
