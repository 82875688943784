import React, { useContext } from "react";
import { QrCodeSection } from "../../Container/ConfirmationContainer";
import { contentContext } from "../../Context/ContentContext";
import * as envConfig from "../../config/envConfig";
var DomesticB2C = function (_a) {
    var confirmation = _a.confirmation, translations = _a.translations, trackingNumber = _a.trackingNumber, postenOrBring = _a.postenOrBring;
    var ctx = useContext(contentContext);
    var image = postenOrBring === 'Posten' ? 'scanner-og-printer.png' : 'scanner-og-printer-bring.png';
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, ctx.t('newconfirmation.info')),
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2 hw-text-center' },
            React.createElement("img", { src: 'images/' + image, alt: '', style: { maxWidth: '60%' } })),
        React.createElement(QrCodeSection, { confirmation: confirmation, translations: ctx.t('newconfirmation.qrCodes'), hasLabelFree: false }),
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3', "data-testid": 'B2CPickupPointHeader' }, translations.pickup.title),
            React.createElement("div", null,
                translations.pickup.info,
                React.createElement("a", { href: 'https://www.posten.no/kart', className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer' }, translations.pickup.infoLink))),
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3' }, translations.sendFromMailbox.title),
            React.createElement("div", { className: 'hw-block hw-block--mt-small-4' },
                translations.sendFromMailbox.printLabel,
                React.createElement("a", { href: confirmation.resp.links.labels, className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer' }, translations.sendFromMailbox.printLabelLink)),
            React.createElement("div", { className: 'hw-block hw-block--mt-small-4' }, translations.sendFromMailbox.info),
            React.createElement("div", { className: 'hw-block hw-block--mt-small-4' },
                React.createElement("a", { href: envConfig.url + trackingNumber, target: '_blank', rel: 'noopener noreferrer', className: 'hw-button hw-button--primary hw-button--small', "data-testid": 'B2COrderPickupFromMailbox' }, translations.sendFromMailbox.mailbox),
                React.createElement("a", { href: 'https://www.posten.no/sporing-kundeservice/sporingsapplikasjon', target: '_blank', rel: 'noopener noreferrer', className: 'hw-button hw-button--primary hw-button--small' }, translations.sendFromMailbox.parcelLocker)))));
};
export default DomesticB2C;
