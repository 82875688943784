import React, { useContext } from 'react'
import { getCountryName } from '../GetCountryName/GetCountryName'
import ContentContext from '../../Context/ContentContext.js'
import { useFormContext } from 'react-hook-form'

const countrySelect = ({ formFor }) => {
  const countryCodes = []
  const context = useContext(ContentContext)
  const { register, trigger, clearErrors, setValue } = useFormContext()
  context.receiverAddresses.map(address => {
    countryCodes.push(address.appliesToCountryCode)
    if (formFor === 'recipient' && countryCodes.indexOf(address.countryCode) === -1) {
      countryCodes.push(address.countryCode)
    }
  })

  const countryOptions = countryCodes.map(code => {
    return <option key={code} value={code}>{context.t(getCountryName(code))}</option>
  })

  const select =
    <select
      className='hw-dropdown--native'
      data-hw-dropdown='countries'
      name={formFor + '.countryCode'}
      id='countries'
      data-testid='countrySelect'
      ref={register({
        required: true
      })}
      disabled={countryCodes.length <= 1}
      onChange={() => { clearErrors(formFor + '.postalCode'); setValue(formFor + '.postalCity', ''); trigger({ name: formFor + '.postalCode' }) }}
    >
      {countryOptions}
    </select>

  return (
    <>
      <label className='hw-label'>
        {context.t('formLabels.country')}
        {select}
      </label>
    </>
  )
}

export default countrySelect
