import React, { useContext } from 'react'
import ContentContext from '../../Context/ContentContext'
import { useFormContext } from 'react-hook-form'

const packagesAndRef = ({ maxColli, onChange, countryCode, reference }) => {
  const ctx = useContext(ContentContext)
  const { register, errors } = useFormContext()
  const noOfPackagesRefGrid = {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap'
  }

  const noofPackagesInput = {
    marginRight: '20px',
    flex: '0.30'
  }

  const referenceInput = {
    flex: '1'
  }

  const numberOfPackages = (maxColli) => {
    if (maxColli === 1) {
      return (
        <input
          type='hidden'
          name='sender.numberOfPackages'
          defaultValue='1'
          ref={register}
        />
      )
    } else {
      return (
        <label className='hw-label' style={noofPackagesInput}>
          {ctx.t('formLabels.numberOfPackages')}
          <input
            className={errors?.sender?.numberOfPackages ? 'hw-input hw-input--error' : 'hw-input'}
            type='number'
            name='sender.numberOfPackages'
            max='50'
            min='1'
            data-testid='numberOfPackagesInput'
            onChange={onChange}
            ref={register({
              required: true,
              max: {
                value: 50,
                message: ctx.t('errormsg.maxpackages')
              },
              min: {
                value: 1,
                message: ctx.t('errormsg.minonepackage')
              }
            })}
          />
          {
                        errors?.sender?.numberOfPackages &&
                          <span className='hw-error'>{errors?.sender?.numberOfPackages?.message}</span>
          }
        </label>
      )
    }
  }

  return (
    <div style={noOfPackagesRefGrid}>
      {countryCode !== 'FI' && numberOfPackages(maxColli)}
      <label className='hw-label' style={referenceInput}>
        {ctx.t('formLabels.reference')}
        <input
          className={errors?.sender?.reference ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          name='sender.reference'
          data-testid='referenceInputField'
          onChange={onChange}
          defaultValue={reference}
          disabled={reference}
          ref={register({
            maxLength: {
              value: 35,
              message: ctx.t('errormsg.fieldlength')
            }
          })}
        />
        {errors?.sender?.reference && <span className='hw-error'> {errors?.sender?.reference?.message}</span>}
      </label>
    </div>
  )
}

export default packagesAndRef
