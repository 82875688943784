import React, { useState, useContext, useEffect } from 'react'
import AddressForm from './AddressForm/AddressForm.jsx'
import ReceiverAddressCard from './ReceiverAddressCard.jsx'
import ContentContext from '../Context/ContentContext.js'
import GenerateLabelBtn from './GenerateLabelBtn.jsx'
import PackagesAndRef from './AddressForm/PackagesAndRef.jsx'
import { useForm, FormProvider } from 'react-hook-form'
import * as Sentry from '@sentry/browser'
import api from '../api'
import {saturdayDelivery1062VasIsPresent} from '../helpers/saturdayDelivery.ts';
import { shouldRequestQrCode } from '../qrCodeService'
import WeightAndDimensions from './AddressForm/WeightAndDimensions.jsx'
import SaturdayDeliveryDialog from './AddressForm/SaturdayDeliveryDialog.tsx'
import { removeAllWhitespaceInString } from '../helpers/stringHelpers'
import { cloneDeep } from 'lodash'

const returForms = ({ authCode, setFetchError, setConfirmation, reference }) => {
  const context = useContext(ContentContext)
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      sender: {
        numberOfPackages: 1
      }
    }
  })


  const findRightReceiverAddress = (arr) => {
    return arr.length >= 1 && arr.find(address => address.appliesToCountryCode === getValues('sender.countryCode'))
      ? arr.find(address => address.appliesToCountryCode === getValues('sender.countryCode'))
      : arr[0]
  }

  const { getValues, formState, reset, handleSubmit, watch } = methods
  const [showRecipientForm, setShowRecipientForm] = useState(false)
  const [validSenderPostCode, setValidSenderPostCode] = useState("");
  const [validRecipientPostCode, setValidRecipientPostCode] = useState(findRightReceiverAddress(context.receiverAddresses).postalCode)
  const [labelURL, setLabelURL] = useState('')
  const onChangeLabelURLReset = () => {
    if (labelURL !== '' && formState.isSubmitted) {
      setLabelURL('')
      reset(getValues())
    }
  }

  useEffect(() => {
    if (!showRecipientForm) {
      setValidRecipientPostCode(findRightReceiverAddress(context.receiverAddresses).postalCode)
    }
  }, [showRecipientForm])

  const receiverTitleGrid = {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto fit-content(50%)',
    gridColumnGap: '0.5rem'
  }

  const selectedReceiver = findRightReceiverAddress(context.receiverAddresses)

  const showConfirmationPage = shouldRequestQrCode(selectedReceiver.returnProduct, watch('sender.countryCode'), selectedReceiver.valueAddedServices?.includes('1288'))

  const maxColli = selectedReceiver.returnProduct === '9650' ? 1 : 10

  const returnProductID = selectedReceiver.returnProduct

  const toggleShowRecipientForm = () => setShowRecipientForm(!showRecipientForm)

  const useDefaultReceiverbutton = (
    <button
      className='hw-button hw-button--small hw-button--secondary'
      data-testid='useDefaultBtn'
      onClick={(e) => {
        e.preventDefault()
        toggleShowRecipientForm()
      }}
    >
      {context.t('btnlabels.usedefault')}
    </button>
  )

  const generateLabel = () => {
    const sender = getValues({ nest: true }).sender
    if (!sender.numberOfPackages) {
      sender.numberOfPackages = sender.weightAndDimensions?.length?.toString()
    }

    const cleanPostalCodeForAddress = (senderOrRecipient) => {
      const senderOrRecipientClone = cloneDeep(senderOrRecipient)
      senderOrRecipientClone.postalCode = removeAllWhitespaceInString(senderOrRecipientClone.postalCode)
      return senderOrRecipientClone;
    }

    api.generateLabel({
      authCode: authCode,
      sender: cleanPostalCodeForAddress(sender),
      recipient: showRecipientForm ? { ...selectedReceiver, ...cleanPostalCodeForAddress(getValues({ nest: true }).recipient) } : selectedReceiver
    })
      .then(resp => {
        const response = {}
        response.resp = resp
        response.sender = cleanPostalCodeForAddress(getValues({ nest: true }).sender)
        response.recipient = showRecipientForm ? { ...selectedReceiver, ...cleanPostalCodeForAddress(getValues({ nest: true }).recipient) } : selectedReceiver
        showConfirmationPage ? setConfirmation(response) : setConfirmation('')
        setLabelURL(response.resp.links.labels)
      })
      .catch(error => {
        Sentry.captureException(error)
        setFetchError(true)
        setLabelURL('')
      })
  }

  const senderCountryCode = watch('sender.countryCode')
  const recipientCountryCode = showRecipientForm ? watch('recipient.countryCode') : selectedReceiver.countryCode
  const senderPostalCode = watch('sender.postalCode');
  const receiverPostalCode = watch('recipient.postalCode') ?? selectedReceiver.postalCode;
  return (
    <FormProvider {...methods}>
      <form
        data-testid='returnForms'
        className='hw-flex hw-flex--twos hw-flex--gutter-larger-desktop'
        onSubmit={handleSubmit(generateLabel)}
      >
        <div className='hw-grid__item hw-large--two-fifths hw--one-whole'>
          <h3 className='hw-block--mb-smallest'>{context.t('sender')}</h3>
          <AddressForm
            formFor='sender' email={true} onChange={onChangeLabelURLReset}
            returnProdID={returnProductID}
            setValidPostCode={setValidSenderPostCode}
            senderCountryCode={senderCountryCode}
            recipientCountryCode={recipientCountryCode}
          />
          <SaturdayDeliveryDialog
            fromPostalCode={senderPostalCode}
            toPostalCode={receiverPostalCode}
            saturdayVas1062IsPresent={saturdayDelivery1062VasIsPresent(selectedReceiver?.valueAddedServices ?? [])}
            validSenderPostCode={validSenderPostCode}
            validRecipientPostCode={validRecipientPostCode}
          />
          <PackagesAndRef
            maxColli={maxColli} onChange={onChangeLabelURLReset}
            countryCode={senderCountryCode}
            reference={reference}
          />
          {
            senderCountryCode === 'FI' &&
              <>
                <h3 className='hw-block--mb-smallest'>{context.t('packages')}</h3>
                <WeightAndDimensions
                  onChange={onChangeLabelURLReset}
                  formFor='sender'
                  product={selectedReceiver.returnProduct}
                  senderCountryCode={senderCountryCode}
                  recipientCountryCode={recipientCountryCode}
                  maxColli={maxColli > 10 ? 10 : maxColli}
                />
              </>
          }
        </div>
        <div className='hw-grid__item hw-large--two-fifths hw--one-whole'>
          <div style={receiverTitleGrid}>
            <h3 className='hw-block--mb-smallest'>{context.t('recipient')}</h3>
            {showRecipientForm && useDefaultReceiverbutton}
          </div>
          {
            showRecipientForm
              ? <AddressForm setValidPostCode={setValidRecipientPostCode} formFor='recipient' onChange={onChangeLabelURLReset} receiver={selectedReceiver} />
              : <ReceiverAddressCard toggle={toggleShowRecipientForm} receiver={selectedReceiver} />
          }
        </div>
        <GenerateLabelBtn
          labelURL={labelURL}
          setLabelURL={setLabelURL}
          open={!showConfirmationPage}
        />
      </form>
    </FormProvider>
  )
}

export default returForms
