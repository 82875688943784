export default (toCountryCode, t) => {
  switch (toCountryCode) {
    case 'NO' :
      return {
        value: /^(\s?|\+47\s?[2-9]\d{7})$/,
        message: t('errormsg.validationphoneNumberNO')
      }
    case 'DK':
      return {
        value: /^(\s?|\+45\s?[0-9]{8})$/,
        message: t('errormsg.validationphoneNumberDK')
      }
    case 'FI':
      return {
        value: /^(\s?|\+358[1-9](\d{5,9}))$/,
        message: t('errormsg.validationphoneNumberFI')
      }
    case 'SE':
      return {
        value: /^(\s?|\+46\s?[0-9]{9})$/,
        message: t('errormsg.validationphoneNumberSE')
      }
    default:
      return ''
  }
}
