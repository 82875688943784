import en from './en'
import nb from './nb'
import sv from './sv'
import da from './da'

function translate (location) {
  const dictionary = locationToLang(location)
  return (key) => {
    let result = dictionary
    key.split('.').forEach((k) => {
      result = result[k]
    })
    return result
  }
}

function locationToLang (url) {
  if (url.includes('bring.no') || url.includes('posten.no')) {
    return nb
  } else if (url.includes('bring.se')) {
    return sv
  } else if (url.includes('bring.dk')) {
    return da
  } else {
    return en
  }
}

export default translate
