import React, { useContext } from "react";
import { contentContext } from "../../Context/ContentContext";
import { QrCodeInformation } from "../../Container/ConfirmationContainer";
function getPickupPointUrl(senderCountry) {
    var url = window.location.href;
    if (senderCountry == 'SE' && url.includes('se')) {
        return 'https://www.bring.se/privat/kundservice/utlamningsstallen';
    }
    else if (senderCountry == 'SE') {
        return 'https://www.bring.se/en/map';
    }
    else if (senderCountry == 'DK' && url.includes('dk')) {
        return 'https://www.bring.dk/kort';
    }
    else if (senderCountry == 'DK') {
        return 'https://www.bring.dk/en/map';
    }
    else {
        return '';
    }
}
var NordicParcels = function (_a) {
    var confirmation = _a.confirmation;
    var ctx = useContext(contentContext);
    var senderCountry = ctx.receiverAddresses[0].appliesToCountryCode;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hw-block hw-block--mb' }, ctx.t('newconfirmation.nordicParcels.info')),
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2 hw-block--mb-large-2' },
            React.createElement("div", { className: "hw-message", style: { display: 'block' } },
                React.createElement("div", { className: "hw-message__body" },
                    React.createElement("div", null,
                        React.createElement(QrCodeInformation, { confirmation: confirmation, translations: ctx.t('newconfirmation.qrCodes'), hasLabelFree: true }))))),
        React.createElement("div", { className: 'hw-block hw-block--mb' },
            React.createElement("div", { className: 'hw-wysiwyg' },
                React.createElement("h2", { "data-testid": 'nordicParcelsInstructionsHeader' }, ctx.t('newconfirmation.nordicParcels.instructions.title')),
                React.createElement("ol", null,
                    React.createElement("li", null, ctx.t('newconfirmation.nordicParcels.instructions.stepOne')),
                    React.createElement("li", null, ctx.t('newconfirmation.nordicParcels.instructions.stepTwo')),
                    React.createElement("li", null, ctx.t('newconfirmation.nordicParcels.instructions.stepThree'))),
                React.createElement("div", { className: 'hw-block hw-block--mt-small-4' },
                    React.createElement("a", { href: getPickupPointUrl(senderCountry), target: '_blank', rel: 'noopener noreferrer', className: 'hw-button hw-button--primary hw-button--small', "data-testid": 'nordicParcelsPickupPoint' }, ctx.t('newconfirmation.nordicParcels.findPostOffice'))))),
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3' }, ctx.t('newconfirmation.nordicParcels.printer.title')),
            React.createElement("div", null,
                React.createElement("a", { href: confirmation.resp.links.labels, className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer', "data-testid": 'nordicParcelsPrintLabel' }, ctx.t('newconfirmation.nordicParcels.printer.info'))))));
};
export default NordicParcels;
