import React, { useContext, useEffect, useState } from 'react'
import 'isomorphic-fetch'
import ContentContext from '../../Context/ContentContext'
import api from '../../api'
import { useFormContext } from 'react-hook-form'
import { removeAllWhitespaceInString, stringHasAllNumbers } from '../../helpers/stringHelpers'

const postalCodeLocationInput = ({ formFor, onChange, setValidPostCode }) => {
  const ctx = useContext(ContentContext)
  const { register, errors, getValues, watch, trigger, setValue } = useFormContext()
  const postalCodeLocationGrid = {
    display: 'flex',
    flexFlow: 'row wrap'
  }
  const postalCodeInputStyle = {
    marginRight: '20px',
    width: '108px'
  }
  const cityStyle = {
    flex: '1'
  }

  const postalCodeWatch = watch(formFor + '.postalCode')
  const postalCityWatch = watch(formFor + '.postalCity')

  const countryCode = getValues(formFor + '.countryCode')

  const postalCodeIsValidForCountryCode = (possiblePostalCode, countryCode) => {

    const strippedPossiblePostalcode = removeAllWhitespaceInString(possiblePostalCode)

    let errmsg = ''
    let isValid = true;

    const norwegianAndDanishIsValid = strippedPossiblePostalcode.length === 4 && stringHasAllNumbers(strippedPossiblePostalcode)
    const swedishAndFinnishIsValid = strippedPossiblePostalcode.length === 5 && stringHasAllNumbers(strippedPossiblePostalcode)
    
    if (new Set(["NO", "DK"]).has(countryCode) && !norwegianAndDanishIsValid) {
      errmsg = ctx.t('errormsg.exactlyfourdigits')
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
      isValid = false;
    } 
    
    if (new Set(["SE", "FI"]).has(countryCode) && !swedishAndFinnishIsValid) {
      errmsg = ctx.t('errormsg.exactlyfivedigits')
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
      isValid = false;
    }

    const validationResult = {
      errorDetails : errmsg === '' && getValues(formFor + '.postalCity') !== ctx.t('errormsg.invalidpostalcode') ? true : errmsg,
      valid : isValid
    }

    return validationResult
  }

  const callPostalCodeAPI = (value, countryCode) => {
    if (postalCodeIsValidForCountryCode(value, countryCode).valid) {

      const strippedPostalCode = removeAllWhitespaceInString(value)

      api.validatePostalCode(strippedPostalCode, countryCode)
        .then(res => {
          if (res.valid) {
            if (!!setValidPostCode) {
              setValidPostCode(strippedPostalCode)
            }
            setValue(formFor + '.postalCity', res.result)
          } else {
            setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
          }
          trigger(formFor + '.postalCode')
        })
    } else {
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
    }
  }

  useEffect(() => {
    if (postalCodeWatch && countryCode && !errors[formFor + '.postalCode']) {
      callPostalCodeAPI(postalCodeWatch, countryCode)
    }
  }, [postalCodeWatch])

  useEffect(() => {
    if (postalCityWatch && postalCityWatch !== ctx.t('errormsg.invalidpostalcode')) {
      trigger(formFor + '.postalCity')
    }
  }, [postalCityWatch])

  return (
    <div style={postalCodeLocationGrid}>
      <label className='hw-label' style={postalCodeInputStyle}>
        {ctx.t('formLabels.postalcode')}
        <input
          name={formFor + '.postalCode'}
          id='PostalCodeInput'
          data-testid={`${formFor}PostalCodeInput`}
          onChange={onChange}
          className={errors[formFor]?.postalCode ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          inputMode='numeric'
          ref={register({
            required: ctx.t('errormsg.cannotbeempty'),
            validate: value => postalCodeIsValidForCountryCode(value, countryCode).errorDetails
          })}
        />
        {errors[formFor]?.postalCode && errors[formFor]?.postalCode?.message && <span className='hw-error'> {errors[formFor]?.postalCode?.message} </span>}
      </label>
      <label className='hw-label' style={cityStyle}>
        {ctx.t('formLabels.city')}
        <input
          type='text'
          name={formFor + '.postalCity'}
          data-testid={`${formFor}CityInput`}
          onChange={onChange}
          className='hw-input'
          ref={register({
            required: true,
            validate: value => value !== ctx.t('errormsg.invalidpostalcode')
          })}
          disabled
        />
      </label>

    </div>
  )
}

export default postalCodeLocationInput
