export function shouldRequestQrCode (service, senderCountry, has1288LabelFree) {

  const qrCodeProducts = [
    '9300', 'SERVICEPAKKE_RETURSERVICE',
    '9600', 'EKSPRESS09_RETURSERVICE',
    '9000', 'BPAKKE_DOR-DOR_RETURSERVICE',
    '9350', '9650'
  ]

  if (senderCountry === 'NO') {
    qrCodeProducts.push('PICKUP_PARCEL_RETURN', 'PICKUP_PARCEL_RETURN_BULK')
  } else if ((senderCountry === 'SE' || senderCountry === 'DK') && has1288LabelFree) {
    qrCodeProducts.push('PICKUP_PARCEL_RETURN', 'PICKUP_PARCEL_RETURN_BULK', 'BUSINESS_PARCEL_RETURN', 'BUSINESS_PARCEL_RETURN_BULK')
  }

  return qrCodeProducts.some(it => it === service)
}

