import React from 'react'
import translate from '../i18n/i18n'

export const contentContext = React.createContext({
  receiverAddresses: [],
  setReceiverAddress: () => {},
  t: translate(window.location.href || '')
})

export default contentContext
