import React, { useContext } from 'react'
import { getCountryName } from './GetCountryName/GetCountryName'
import ContentContext from '../Context/ContentContext'

const receiverAddressCard = ({ toggle, receiver }) => {
  const ctx = useContext(ContentContext)
  const editbtn =
    <div className='hw-block hw-block--full hw-text-right'>
      <button
        className='hw-button hw-button--small hw-button--secondary'
        type='button'
        onClick={toggle}
        data-testid='editReceiverBtn'
      >
        {ctx.t('btnlabels.editreceiver')}
      </button>
    </div>

  const withBorderAndPadding = {
    border: '4px solid var(--hw-color-gray-light-stroke)',
    padding: 'var(--hw-spacing-small-4)'
  }

  return (
    <div className='hw-callout  hw-block--mb-smaller' style={withBorderAndPadding}>
      <div className='block'>
        <h3 className='hw-h3' data-testid='receiverCardName'>{receiver.name}</h3>
      </div>
      <div className='block hw-block--mt-smaller hw-block--mb-smaller'>
        <hr className='hw-hr hw-hr--gray-light hw-hr--full' />
      </div>
      <p>
        {receiver.addressLine1}<br />
        {receiver.addressLine2}<br />
        {receiver.postalCode} {receiver.postalCity}
        <br />{ctx.t(getCountryName(receiver.countryCode))}
      </p>

      {receiver.editable ? editbtn : null}
    </div>
  )
}

export default receiverAddressCard
