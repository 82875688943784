import React, { useContext, useEffect } from 'react'
import Img from 'react-image'
import * as envConfig from '../config/envConfig.js'

import ContentContext from '../Context/ContentContext'

export const Confirmation = ({ confirmation }) => {
  const ctx = useContext(ContentContext)
  const confirmResp = confirmation.resp
  const confirmRecipient = confirmation.recipient

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isProduct9650 = () => {
    return confirmRecipient?.returnProduct === '9650'
  }

  const renderPrintLabelLinks = (() => {
    if (isProduct9650()) {
      return (
        <p className='hw-block hw-block--mb'>
          <a
            href={confirmResp.links.labels + '?resize=a4'} target='_blank' rel='noopener noreferrer'
            className='hw-button hw-button--primary hw-block--mb-smaller'
            data-testid='viewStandardLabelLink'
          >
            {ctx.t('confirmation.printlabelregular')}
          </a>
          <div>
            <a
              className='hw-link'
              href={confirmResp.links.labels}
              target='_blank' rel='noopener noreferrer'
              data-testid='viewSpecialLabelLink'
            >
              {ctx.t('confirmation.printlabelspecial')}
            </a>
          </div>
        </p>
      )
    }

    return (
      <p className='hw-block hw-block--mb'>
        <a
          href={confirmResp.links.labels} target='_blank' rel='noopener noreferrer'
          className='hw-button hw-button--primary'
          data-testid='viewLabelLink'
        >
          {ctx.t('confirmation.printlabel')}
        </a>
      </p>
    )
  })()

  return (
    <div className='hw-callout  hw-block--mb-smaller'>
      <div className='block'>
        <h3 className='hw-h3' data-testid='confirmationHeader'>{ctx.t('confirmation.title')}</h3>
      </div>

      <hr className='hw-hr hw-hr--gray-lighter hw-hr--full hw-block--mb-smaller' />

      <div className='hw-block'>
        {renderPrintLabelLinks}
        {confirmRecipient.returnProduct === '9650' &&
          <div className='block'>
            <h3 className='hw-h3 hw-block--mb' data-testid='pickupOrderHeader'>{ctx.t('confirmation.orderpickup.contentheader')}
            </h3>
            <p className='hw-block hw-block--mb'>{ctx.t('confirmation.orderpickup.para1')}
            </p>
            <p className='hw-block hw-block--mb'>{ctx.t('confirmation.orderpickup.para2')}
            </p>
            <a
              href={envConfig.url + confirmResp?.consignmentNumber ?? confirmResp?.packages?.find(item => item !== undefined)?.packageNumber} target='_blank' rel='noopener noreferrer'
              className='hw-button hw-button--primary'
              data-testid='viewOrderpickuplink'
            >
              {ctx.t('confirmation.orderpickup.buttonLabel')}
            </a>
          </div>}
      </div>

      {confirmResp.packages[0].qrCodeLink &&
        <div className='block'>
          <h3 className='hw-h3' data-testid='qrCodeHeader'>{ctx.t('confirmation.qrcodes.title')}</h3>
          <p className='hw-block'>{ctx.t('confirmation.qrcodes.intro')}</p>
          <p className='hw-block hw-block--mb'>{ctx.t('confirmation.qrcodes.otherwise')}</p>
          <p className='hw-block'>{ctx.t('confirmation.qrcodes.here')}:</p>
          {confirmResp.packages.map(p => {
            return <Img data-testid='qrCodeImage' key={p.packageNumber} src={p.qrCodeLink} />
          })}
          <p className='hw-block'>{ctx.t('confirmation.qrcodes.once')}</p>
        </div>}

    </div>
  )
}
export default Confirmation
