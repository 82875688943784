import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://17e5f324793f4550ac1b286653dccec6@sentry.io/1723256',
    environment: environment(window.location.href || ''),
    whitelistUrls: [
      // retur(n).qa.bring.XX
      'retur.qa.bring.no',
      'retur.qa.bring.se',
      'retur.qa.bring.dk',
      'retur.qa.bring.fi',
      'return.qa.bring.com',
      // retur.qa.posten.no
      'retur.qa.posten.no',
      // retur(n).bring.XX
      'retur.bring.no',
      'retur.bring.se',
      'retur.bring.dk',
      'retur.bring.fi',
      'return.bring.com',
      // retur.posten.no
      'retur.posten.no',
      'cdn.jsdelivr.net/npm/@posten/hedwig@10.4.13/'
    ]
  })
}

function environment (url) {
  if (url.includes('test')) {
    return 'test'
  } else if (url.includes('qa')) {
    return 'qa'
  } else {
    return 'prod'
  }
}

ReactDOM.render(<App />, document.querySelector('#root'))
