import React, { useContext } from 'react'
import ContentContext from '../Context/ContentContext'

const navbar = () => {
  const context = useContext(ContentContext)
  const postenOrBring = (window.location.href || '').includes('posten') ? 'posten' : 'bring'

  return (
    <header className='hw-navbar'>
      <div className='hw-navbar__content'>

        <div className='hw-navbar__top'>
          <a href='/' className='hw-navbar__logo'>
            <svg className='hw-navbar__logo-standard' aria-labelledby='Logo' role='img'>
              <title id='Logo'>{context.t('gotohomepagelink')}</title>
              <use xlinkHref={'#logo-' + postenOrBring} />
            </svg>
            <svg className='hw-navbar__logo-gray' aria-labelledby='Logo' role='img'>
              <title id='Logo'>{context.t('gotohomepagelink')}</title>
              <use xlinkHref={'#logo-' + postenOrBring + '-gray'} />
            </svg>
          </a>
        </div>
      </div>
    </header>
  )
}

export default navbar
