import React, { useContext } from 'react'
import ContentContext from '../Context/ContentContext'

const invalidReturnAgreementMessage = () => {
  const ctx = useContext(ContentContext)

  return (
    <div className='hw-error-summary' data-testid='noResultMessage'>
      <div className='hw-error-summary__title'>
        <h2>{ctx.t('errormsg.invaliderrorhappened')}</h2>
      </div>
      <div className='hw-error-summary__contents'>
        <ul className='hw-list hw-list--small'>
          <li>{ctx.t('errormsg.invalidcontactonlinestore')}</li>
        </ul>
      </div>
    </div>
  )
}

export default invalidReturnAgreementMessage
