import React, { useContext } from 'react'
import ContentContext from '../../Context/ContentContext'

const pageTitle = () => {
  const ctx = useContext(ContentContext)

  return (
    <>
      <div className='hw-block hw-block--mb'>
        <h1 className='hw-color-white hw-text-medium'>{ctx.t('pageTitle.title')}</h1>
      </div>
    </>
  )
}

export default pageTitle
