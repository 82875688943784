import React, { useContext, useEffect } from 'react'
import ContentContext from '../../Context/ContentContext'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Mybicon } from '@norwaypost/mybring-iconsystem'
import './weightAndDimensions.css'
import {
  getLimits,
  isWithinDimensionLimits,
  isWithinWeightLimits,
  getMaxWeight
} from '@posten-libs/mybring-service-country-vas-common'

const noOfPackagesRefGrid = {
  alignItems: 'flex-end',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between'
}

const dimensionsInputStyle = {
  marginRight: '3px'
}

const weightAndDimensions = ({ formFor, onChange, product, senderCountryCode, recipientCountryCode, maxColli }) => {
  const ctx = useContext(ContentContext)
  const { register, control, getValues, trigger, formState } = useFormContext()
  const { errors } = formState
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sender.weightAndDimensions'
  })
  if (recipientCountryCode === undefined) {
    recipientCountryCode = 'NO'
  }

  const limits = getLimits(product, senderCountryCode, recipientCountryCode)

  const validateWeight = value => {
    return (!!value && value >= 0 && isWithinWeightLimits(product, value, senderCountryCode, recipientCountryCode, false)) ||
      ctx.t('errormsg.weight') + limits.minWeight + ' - ' +
      getMaxWeight(product, senderCountryCode, recipientCountryCode, false).weightInKgs + ' kg'
  }

  const allValuesPresent = values => values.every(v => v > 0)

  const validateDimensions = (index) => {
    const dimensionsRaw = getValues([`sender.weightAndDimensions[${index}].length`, `sender.weightAndDimensions[${index}].width`, `sender.weightAndDimensions[${index}].height`])
    const dimensions = [dimensionsRaw?.sender?.weightAndDimensions[index]?.length, dimensionsRaw?.sender?.weightAndDimensions[index]?.width, dimensionsRaw?.sender?.weightAndDimensions[index]?.height]
    const packageDimensions = {
      length: dimensions[0],
      width: dimensions[1],
      height: dimensions[2]
    }

    if (!allValuesPresent(dimensions)) {
      return ctx.t('errormsg.missingdimensionsfield')
    }
    return isWithinDimensionLimits(product, packageDimensions, senderCountryCode, recipientCountryCode) ? true
      : ctx.t('errormsg.dimensions1') + limits.circumference.maxLongestSide + ' cm. ' + ctx.t('errormsg.dimensions2') + limits.circumference.maxCircumferencePlusLongestSide + ' cm.'
  }

  const ensureOneDecimal = (ev) => {
    ev.target.value = parseFloat(ev.target.value).toFixed(1)
  }

  const ensureNoDecimalsAndMax3digits = (ev) => {
    ev.target.value = parseInt(ev.target.value) > 999 ? 999 : parseInt(ev.target.value)
  }

  const weightAndDimensionsMarkup = (field, idx) => {
    return (
      <div key={field.id}>
        <div style={noOfPackagesRefGrid}>
          <label className='hw-label' style={dimensionsInputStyle}>
            {idx < 1 && ctx.t('weightAndDimensions.weight')}
            <input
              className={errors?.sender?.weightAndDimensions?.[idx]?.weight ? 'hw-input hw-input--error' : 'hw-input'}
              type='number'
              min={limits.minWeight}
              max='99'
              step='0.1'
              onBlur={ensureOneDecimal}
              onChange={onChange}
              name={`sender.weightAndDimensions[${idx}].weight`}
              data-testid={`sender.weightAndDimensions[${idx}].weight.testid`}
              ref={register({ required: ctx.t('errormsg.missingdimensionsfield'), validate: validateWeight })}
              defaultValue={field.weight}
            />
          </label>
          <label className='hw-label' style={dimensionsInputStyle}>
            {idx < 1 && ctx.t('weightAndDimensions.length')}
            <input
              className={errors?.sender?.weightAndDimensions?.[idx]?.length ? 'hw-input hw-input--error' : 'hw-input'}
              type='number'
              min='0'
              max='999'
              step='1'
              name={`sender.weightAndDimensions[${idx}].length`}
              onBlur={ensureNoDecimalsAndMax3digits}
              onChange={() => {
                trigger([`sender.weightAndDimensions[${idx}].width`, `sender.weightAndDimensions[${idx}].height`])
                onChange()
              }}
              data-testid={`sender.weightAndDimensions[${idx}].length.testid`}
              ref={register({
                required: ctx.t('errormsg.missingdimensionsfield'),
                validate: () => validateDimensions(idx)
              })}
              defaultValue={field.length}
            />
          </label>
          <label className='hw-label' style={dimensionsInputStyle}>
            {idx < 1 && ctx.t('weightAndDimensions.width')}
            <input
              className={errors?.sender?.weightAndDimensions?.[idx]?.width ? 'hw-input hw-input--error' : 'hw-input'}
              type='number'
              min='0'
              max='999'
              step='1'
              name={`sender.weightAndDimensions[${idx}].width`}
              onBlur={ensureNoDecimalsAndMax3digits}
              onChange={() => {
                trigger([`sender.weightAndDimensions[${idx}].length`, `sender.weightAndDimensions[${idx}].height`])
                onChange()
              }}
              data-testid={`sender.weightAndDimensions[${idx}].width.testid`}
              ref={register({
                required: ctx.t('errormsg.missingdimensionsfield'),
                validate: () => validateDimensions(idx)
              })}
              defaultValue={field.width}
            />
          </label>
          <label className='hw-label' style={dimensionsInputStyle}>
            {idx < 1 && ctx.t('weightAndDimensions.height')}
            <input
              className={errors?.sender?.weightAndDimensions?.[idx]?.height ? 'hw-input hw-input--error' : 'hw-input'}
              type='number'
              min='0'
              max='999'
              step='1'
              name={`sender.weightAndDimensions[${idx}].height`}
              onBlur={ensureNoDecimalsAndMax3digits}
              onChange={() => {
                trigger([`sender.weightAndDimensions[${idx}].length`, `sender.weightAndDimensions[${idx}].width`])
                onChange()
              }}
              data-testid={`sender.weightAndDimensions[${idx}].height.testid`}
              ref={register({
                required: ctx.t('errormsg.missingdimensionsfield'),
                validate: () => validateDimensions(idx)
              })}
              defaultValue={field.height}
            />
          </label>

          <label className='hw-label' style={dimensionsInputStyle}>
            <button disabled={fields.length >= maxColli} onClick={append} className='hw-button hw-button--secondary' data-testid={`sender.weightAndDimensions[${idx}].add.testid`}>
              <Mybicon name='mybicon-plus' width={20} height={20} />
            </button>
          </label>

          <label className='hw-label'>
            <button
              disabled={idx < 1 && fields.length === 1} onClick={() => remove(idx)}
              className='hw-button hw-button--secondary'
              data-testid={`sender.weightAndDimensions[${idx}].remove.testid`}
            >
              <Mybicon name='mybicon-trash' width={20} height={20} />
            </button>
          </label>
        </div>
        {
          errors?.sender?.weightAndDimensions?.[idx] &&
            <span style={{ fontSize: '16px', textAlign: 'right', display: 'block' }} data-testid={`sender.weightAndDimensions[${idx}].msg.testid`}>
              {errors?.sender?.weightAndDimensions?.[idx]?.weight?.message ||
            errors?.sender?.weightAndDimensions?.[idx]?.length?.message ||
            errors?.sender?.weightAndDimensions?.[idx]?.width?.message ||
            errors?.sender?.weightAndDimensions?.[idx]?.height?.message}
            </span>
        }
      </div>
    )
  }

  useEffect(() => {
    append({}, false)
  }, [])

  return (
    <div>
      {fields.map((field, idx) => weightAndDimensionsMarkup(field, idx))}
    </div>
  )
}

export default weightAndDimensions
