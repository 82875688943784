const da = {
  pageTitle: {
    title: 'Bestil ny retur'
  },
  authCodeSearch: {
    inputAuthCodeLabel: 'Indtast returkode',
    inputAuthCodePlaceholder: 'Ex. 35424374',
    noresult: 'Fandt ikke din returkode',
    checkifcorrectcode: 'Har du skrevet den rigtige kode?',
    authcodeisnotshippingcode: 'Returkoden får du fra afsenderen af den pakke, som du har modtaget. Bemærk at forsendelsesnummeret for denne pakke ikke er returkoden'
  },
  formLabels: {
    name: 'Navn',
    address: 'Addresse',
    addressline2: 'Addresselinje 2',
    phoneNumber: 'Mobilnummer',
    email: 'E-mail - hvis du vill modtage etiket/QR-kode via e-mail',
    emailWithoutQrCode: 'E-mail - hvis du vill modtage etiket via e-mail',
    postalcode: 'Postnummer',
    city: 'Sted',
    country: 'Land',
    numberOfPackages: 'Antal kolli',
    reference: 'Reference'
  },
  confirmation: {
    title: 'Bestillingsbekræftelse',
    from: 'Fra',
    to: 'Til',
    printlabel: 'Udskriv returetiket',
    printlabelregular: 'Udskriv returetiketten på en almindelig printer (A4)',
    printlabelspecial: 'Udskriv returetiketten på en speciel etiketprinter',
    trackingnumber: 'Sporingsnummer',
    orderpickup: {
      buttonLabel: 'Bestil Pickup fra postkassen',
      contentheader: 'Pickup fra postkassen',
      para1: 'Du kan nemt bestille pickup af returen, direkte fra din egen postkasse. Hvis pickup er inkluderet for denne retur, bekræfter du kun pickupdatoen. Alternativt koster tillægstjenesten NOK 15. For at vi kan vide, hvem du er, og sørge for, at vi har den rigtige adresse, skal du logge ind, inden du bestiller.'
    },
    qrcodes: {
      title: 'QR-kode',
      intro: 'Du kan udskrive returlabelen på alle Postens indleveringssteder ved at scanne QR-koden herunder.',
      otherwise: 'Du kan også selv udskrive returlabelen på forhånd, hvis du ønsker det.',
      here: 'Her er din QR-kode (én per kolli)',
      once: 'QR-koden kan kun bruges én gang.'
    }
  },
  weightAndDimensions: {
    weight: 'Vægt (kg)',
    length: 'Længde (cm)',
    width: 'Bredde (cm)',
    height: 'Højde (cm)'
  },
  errormsg: {
    invalidpostalcode: 'Ugyldig postnummer',
    cannotbeempty: 'Skal udfyldes',
    maxpackages: 'Maks 50',
    minonepackage: 'Min 1',
    errorhappened: 'Vi kan ikke udføre søgninger i øjeblikket',
    trylaterrefresh: 'Opdater siden, og prøv igen senere',
    fieldlength: 'Skal være mellem 1 og 35 tegn',
    exactlyfourdigits: 'Skal være nøjagtigt 4 cifre',
    exactlyfivedigits: 'Skal være nøjagtigt 5 cifre',
    emailformat: 'Ugyldig e-mail-adresse',
    weight: 'Ugyldig vægt. Skal være ',
    missingdimensionsfield: 'Vægt og mål skal legges inn',
    dimensions1: 'Længste side maks ',
    dimensions2: 'Længste side + omkreds maks ',
    validationphoneNumberNO: 'Nummeret skal være et gyldigt norsk telefonnummer (+47 12345678)',
    validationphoneNumberSE: 'Nummeret skal være et gyldigt svensk telefonnummer(+46 700000000)',
    validationphoneNumberDK: 'Nummeret må være et gyldigt dansk telefonnnummer (+45 12345678)',
    validationphoneNumberFI: 'Nummeret skal være et gyldigt finsk telefonnummer (+358201234567)'
  },
  sender: 'Afsender',
  packages: 'Pakker',
  recipient: 'Modtager',
  gotohomepagelink: 'Gå til forsiden',
  btnlabels: {
    search: 'Søg',
    editreceiver: 'Rediger modtager',
    generatelabel: 'Opret returlabel',
    creatinglabel: 'Opretter returlabel...',
    usedefault: 'Brug standard',
    searching: 'Søger..',
    viewreturnlabel: 'Vis returlabel'
  },
  countries: {
    norway: 'Norge',
    denmark: 'Danmark',
    sweden: 'Sverige',
    finland: 'Finland'
  },
  saturdayDelivery: {
    eligible: 'Returneringen forventes udleveret efter helgen, men kan fremskyndes til førstkommende lørdag.',
    eligibleUnconfirmed: 'Vi har tekniske problemer, og kan ikke verificere, at lørdagslevering vil fremskynde returnering.',
    checkboxText: 'Bestil udlevering lørdag'
  },
  newconfirmation: {
    back: 'Tilbage',
    title: 'Din returforsendelse er bestilt.',
    info: 'QR-koden kan bruges til at udskrive adresselappen hos Posten, eller du kan udskrive den selv.',
    qrCodes: {
      title: 'Din QR-kode',
      titleMultiple: 'Dine QR-koder (én pr. kolli)',
      package: 'kolli',
      of: 'af',
      sentTo: 'QR-koden er også sendt til',
      instructions: {
        title: 'Sådan gør du:',
        stepOne: 'Besøg din nærmeste Posten indleveringssted.',
        stepTwo: 'Scan QR-koden. Labelen udskrives automatisk.',
        stepThree: 'Fastgør labelen midt på pakken med stregkoden tydeligt synlig.',
        stepFour: 'Indlever pakken.',
        pickupPoints: 'Her kan du finde vores Post i Butikk og andre indleveringssteder.',
      },
    },
    printer: {
      title: 'Har du en printer til rådighed?',
      info: 'Hvis du har adgang til en printer, kan du også',
      printLabel: 'selv udskrive labelen.',
    },
    pickup: {
      title: 'Send retur fra din postkasse?',
      info: 'Når du har printet og sat returlabel på, kan du ',
      orderPickup: 'bestille afhentning fra din postkasse.',
    },
    tracking: {
      title: 'Sådan sporer du din retur',
      titleMultiple: 'Sådan sporer du din retur',
      info: 'Når du har leveret din retur til et af vores afleveringssteder, kan du spore din pakke med kollinummer ',
      infoMultiple: 'Når du har leveret din retur til et af vores afleveringssteder, kan du spore din pakke med sendingsnummer ',
      at: ' på posten.no.'
    },
    newB2CReturnWith1073: {
      pickup: {
        title: 'Vores indleveringssteder',
        info: 'Finn dit ',
        infoLink: 'nærmeste Posten indleveringssted.',
      },
      sendFromMailbox: {
        title: 'Sender du returen fra postkassen eller pakkeboksen?',
        printLabel: 'Hvis du har adgang til en printer, kan du ',
        printLabelLink: 'udskrive labelen selv.',
        info: 'Når du har returlabelen klar, er du velkommen til at sende pakken fra både din egen postkasse eller brug pakkeboks gennem Posten-appen.',
        mailbox: 'Bestil afhentning fra postkassen',
        parcelLocker: 'Åbne Posten-appen',
      },
    },
    nordicParcels: {
      info: 'Besøg en Bring pakkeshop og vis QR-kode for at få din returlabel printet.',
      instructions: {
        title: 'Sådan gør du:',
        stepOne: 'Find din nærmeste Bring pakkeshop.',
        stepTwo: 'Vis din QR-kode.',
        stepThree: 'Personalet printer din label ud.'
      },
      findPostOffice: 'Find en Bring pakkeshop',
      printer: {
        title: 'Har du egen printer?',
        info: 'Print selv label.',
      },
    },
  },
}

export default da